import "./components/gagsstaking.css";
import GagsStaking from "./components/GagsStaking";



function App() {
  return (
    <div className="App">
   <GagsStaking/>
    </div>
  );
}

export default App;
