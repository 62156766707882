import "./gagsstaking.css";
import { useState, useEffect } from "react";
import { GiBoltSpellCast } from "react-icons/gi";
import stakingAbi from "../Abi/stakingAbi.json";
import nftAbi from "../Abi/nftAbi.json"
import { stakingAddress, nftAddress } from "../contract/Address"
import { ExternalLink } from 'react-external-link';

const gifImage_3d = new URL("./assets/images/gags_3d.gif", import.meta.url);


function GagsStaking() {
    const [account, setAccount] = useState("");
    const [provider, setProvider] = useState("");
    const [signer, setSigner] = useState("");
    const { ethers } = require("ethers");



    let tokenIdGags_3d;
    let tokenStakedGags_3d;
    let totalOfStakedUser_3d;

    async function getApprovedForAllGags_3d(e) {
        e.preventDefault();
        try {
            const contract = new ethers.Contract(
                nftAddress,
                nftAbi,
                signer,
                provider
            );
            await contract.setApprovalForAll(stakingAddress, true);
        } catch (e) {

        }

    }



    async function verifyApprovalGags_3d(e) {
        e.preventDefault();
        try {
            const contract = new ethers.Contract(
                nftAddress,
                nftAbi,
                signer,
                provider
            );
            const verifyGags_3d = await contract.isApprovedForAll(
                account,
                stakingAddress
            );
            document.getElementById("verifiedGags_3d").textContent = verifyGags_3d;
        } catch (e) {


        }
    }



    async function ownerStakedNftGags_3d(e) {
        e.preventDefault();
        try {
            const contract = new ethers.Contract(
                stakingAddress,
                stakingAbi,
                signer,
                provider
            );

            tokenStakedGags_3d = await contract.stakedNFTSByUserGags_3d(account);
            document.getElementById("ownertokenStakedGags_3d").value = tokenStakedGags_3d;
            alert(tokenStakedGags_3d);
        } catch (e) {
        }
    }




    async function ownertokenIdGags_3d(e) {
        e.preventDefault();
        try {
            const contract = new ethers.Contract(
                nftAddress,
                nftAbi,
                signer,
                provider
            );

            tokenIdGags_3d = await contract.walletOfOwner(account);
            alert(tokenIdGags_3d);
        } catch (e) {
        }
    }



    async function nftStakeGags_3d(e) {
        e.preventDefault();
        try {
            const contract = new ethers.Contract(
                stakingAddress,
                stakingAbi,
                signer,
                provider
            );
            const stakesGags_3d = document.querySelector("[name=sIdGags_3d]").value;
            await contract.stakeGags_3d(stakesGags_3d);
        } catch (e) {
        }
    }


    async function nftUnStakeGags_3d(e) {
        e.preventDefault();
        try {
            const contract = new ethers.Contract(
                stakingAddress,
                stakingAbi,
                signer,
                provider
            );
            const unstakesGags_3d = document.querySelector("[name=usIdGags_3d]").value;
            await contract.unstakeGags_3d(unstakesGags_3d);
        } catch (e) {
        }
    }



    async function nftStakeBatchGags_3d(e) {
        e.preventDefault();
        try {
            const contract = new ethers.Contract(
                stakingAddress,
                stakingAbi,
                signer,
                provider
            );
            await contract.stakeBatchGags_3d(tokenIdGags_3d);
        } catch (e) {
        }
    }



    async function nftUnStakeBatchGags_3d(e) {
        e.preventDefault();
        try {
            const contract = new ethers.Contract(
                stakingAddress,
                stakingAbi,
                signer,
                provider
            );
            await contract.unstakeBatchGags_3d(tokenStakedGags_3d);
        } catch (e) {

        }
    }


    async function gags_3dClaimRewards(e) {
        e.preventDefault();
        try {
            const contract = new ethers.Contract(
                stakingAddress,
                stakingAbi,
                signer,
                provider
            );
            await contract.harvestBatchGags_3d(account);
        } catch (e) {
        }
    }


    async function totalOfStakedNft_3d(e) {
        e.preventDefault();
        try {
            const contract = new ethers.Contract(
                stakingAddress,
                stakingAbi,
                signer,
                provider
            );
            totalOfStakedUser_3d = await contract.tokensStakedByUserGags_3d(account);
            document.getElementById("totalGags_3d").textContent = totalOfStakedUser_3d;
        } catch (e) {
        }
    }



    async function rewardsPendingGags_3d(e) {
        e.preventDefault();
        try {
            const contract = new ethers.Contract(
                stakingAddress,
                stakingAbi,
                signer,
                provider
            );
            const pendingGags_3d = document.querySelector(
                "[name=pendingRGags_3d]"
            ).value;
            const rewards_3d = await contract.pendingRewardsGags_3d(
                account,
                pendingGags_3d
            );
            alert(rewards_3d / 1e18);
        } catch (e) {
        }
    }


    async function initConnection() {
        if (typeof window.ethereum !== "undefined") {
            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });

                const tempProvider = new ethers.providers.Web3Provider(window.ethereum);
                setProvider(tempProvider);
                setAccount(accounts[0]);
                const tempSigner = tempProvider.getSigner();
                setSigner(tempSigner);
                console.log("Connected");
            } catch (e) {

            }
        } else {
            console.log("Please install metamask.");


        }
    }

    useEffect(() => { }, []);

    return (
        <div className="page">
            <div className="header">
                <p>
                    Staking Dapp{""}
                    <span>
                        <GiBoltSpellCast />
                    </span>
                </p>
                {account === "" ? (
                    <button onClick={initConnection} className="button">
                        CONNECT WALLET
                    </button>
                ) : (
                    <p>...{account.substring(account.length - 7)}</p>
                )}
            </div>
            <div className="div1">
                {account === "" ? (
                    <form className="form">
                        <h1 className="h"> Gags_3D Collection </h1>
                        <h1 className="h"> Opensea </h1>
                        <span className="h1">
                            <ExternalLink href="https://opensea.io/collection/gags-3d" />
                        </span>
                        <br/><br/><br/>
                        <img src={gifImage_3d} alt="Gags_3d gif" />
                    </form>
                ) : (
                    <form className="form">
                        <div className="form">
                            <h2 className="h">Approval Gags_3D</h2>
                            <button onClick={getApprovedForAllGags_3d} className="button2">
                                APPROVE TO STAKE
                            </button>
                            <br />
                            <button onClick={verifyApprovalGags_3d} className="button2">
                                READY TO STAKE
                            </button>
                            <p id="verifiedGags_3d" />
                            <button onClick={totalOfStakedNft_3d} className="button2">
                                Your Total Staked
                            </button>
                            <p id="totalGags_3d" />
                        </div>
                    </form>
                )}

                {account === "" ? (
                    <p></p>
                ) : (
                    <form className="form">
                        <h2 className="h">Stake Gags_3D</h2>
                        <div className="form">
                            <button onClick={ownertokenIdGags_3d} className="button2">
                                Confirm to Stake
                            </button>
                            <button onClick={nftStakeBatchGags_3d} className="button2">
                                Stake All
                            </button>
                        </div>
                        <div className="form">
                            <button onClick={nftStakeGags_3d} className="button2">
                                Stake
                            </button>
                            <input
                                type="text"
                                name="sIdGags_3d"
                                placeholder="tokenId"
                                className="input"
                            />
                            <p id="ownerNftGags_3d" />
                        </div>
                        <div className="form">
                            <button onClick={ownerStakedNftGags_3d} className="button2">
                                Confirm to Unstaked
                            </button>
                            <p id="ownertokenStakedGags_3d" />
                            <button onClick={nftUnStakeBatchGags_3d} className="button2">
                                Unstaked All
                            </button>
                        </div>
                        <div className="form">
                            <button onClick={nftUnStakeGags_3d} className="button2">
                                Unstake
                            </button>
                            <input
                                type="text"
                                name="usIdGags_3d"
                                placeholder="tokenId"
                                className="input"
                            />
                        </div>
                    </form>
                )}
                {account === "" ? (
                    <p></p>
                ) : (
                    <form className="form">
                        <div className="form">
                            <h2 className="h">Claim Gags_3D</h2>
                            <button onClick={rewardsPendingGags_3d} className="button2">
                                Check Rewards
                            </button>
                            <input
                                type="text"
                                name="pendingRGags_3d"
                                placeholder="tokenId"
                                className="input"
                            />
                        </div>
                        <div className="form">
                            <button onClick={gags_3dClaimRewards} className="button2">
                                Claim All Rewards
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
}

export default GagsStaking;
